.DataList {
  padding: 20px;
  text-align: left;
}

.DataList> :nth-child(2) {
  font-size: 15px;
}

.List {
  flex: 1;
  width: 100%;
  height: 100%;
}

.ListItem {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  display: flex;
}

.Background {
  font-size: 12px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background-color: #e74c3c;
  color: white;
  padding: 5px;
  font-weight: bold;
}

.Wrapper {
  position: relative;
  transition: max-height 0.5s ease;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
}

.BouncingListItem {
  transition: transform 0.5s ease-out;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--navy);
  height: 100%;
  display: flex;
}

.container-swipeable {
  cursor: grab;
}