// Here you can add other styles
$enable-dark-mode: false;

::-webkit-scrollbar {
    // width: 0px;
}

.w-20{
    width: 20% !important;
}

.w-30{
    width: 30% !important;
}

input[name="chooseDesign"] {
    border: 2px solid #a9a9a9
}

.form-label {
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    // max-width: 170px;
    overflow: hidden;
}

.no-wrap {
    flex-wrap: nowrap;
}

.col-2 {
    width: 20% !important;
}

.css-1jqq78o-placeholder>input:placeholder-shown {
    text-overflow: ellipsis;
}

.css-t3ipsp-control:active,
.css-t3ipsp-control:hover,
.css-t3ipsp-control:focus,
.css-t3ipsp-control:focus-visible,
.css-t3ipsp-control:focus-within {
    z-index: 999 !important;
}

input[list=time_list]>*:hover {
    border: none;
    outline: none;
    background-color: rgb(222, 235, 255);
}

.form-control:focus {
    border: 2px solid;
    border-color: #2684FF;
    box-shadow: none;
}

.form-control> :hover {
    border: none;
}

.expandable {
    --cui-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23323a49%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    background-image: var(--cui-form-select-bg-img);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    cursor: pointer;
}

div.item {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    width: 170px;
}

.col-sm-auto img {
    // width: 100px;
    // height: 100px;
}

.caption {
    font-weight: bold;
    display: block;
}

.input-group-text.voucher {
    min-width: 170px;
    color: #fff;
    background-color: var(--cui-success);
}

.input-group .btn {
    z-index: inherit;
}

.input-group.container-swipeable {
    cursor: grab;
    background-color: white;
}

.dropdown-toggle {
    min-width: 150px;
}

// .dropdown-item {
//     display: grid !important;
// }

div.form-control.disabled {
    color: $input-disabled-color;
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
}

.sidebar-nav {
    scrollbar-width: none;
}


.chartjs-tooltip{
    min-width: 180px;
    background: rgba(0,0,0,.7);
    border-radius: 0.375rem;
    color: #fff;
    opacity: 0;
    padding: 0.25rem 0.5rem;
    pointer-events: none;
    position: absolute;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    transition: all .15s ease;
    z-index: 1021;
}

b.form-error::before {
    display: inline;
}

b.form-error{
    color: #dc3545;
    font-size: small;
}

.caret-none{
    caret-color: transparent;
}

@media screen and (max-width: 730px) {
    .no-wrap {
        flex-wrap: wrap;
    }

    .css-1bswcx6-container {
        width: 100% !important;
    }

    .input-group>.form-control {
        width: inherit;
    }
}