/*
Project:	Toggle Switchy
Version:	1.14
Updated:	8/20/2020
Author:		Adam Culpepper | @adamculpepper
Website:	https://toggleswitchy.com
Docs:		https://github.com/adamculpepper/toggle-switchy
Issues:		https://github.com/adamculpepper/toggle-switchy/issues
Releases:	https://github.com/adamculpepper/toggle-switchy/releases
*/

/* Customizable styles */

/* Colors: Default (blue) */
.toggle-switchy {
    color: #fff;
}

.toggle-switchy>input+.toggle:before {
    content: 'ON';
}

.toggle-switchy>input+.toggle:after {
    content: 'OFF';
}

.toggle-switchy>input+.toggle>.switch {
    background: #fff;
}

.toggle-switchy>input+.toggle+.label {
    color: #000;
}

.toggle-switchy>input:checked+.toggle {
    background: #3498db;
}

.toggle-switchy>input:not(:checked)+.toggle {
    background: #ccc;
}

.toggle-switchy>input:checked+.toggle>.switch {
    border: 3px solid #3498db;
}

.toggle-switchy>input:not(:checked)+.toggle>.switch {
    border: 3px solid #ccc;
}

.toggle-switchy>input:focus+.toggle,
.toggle-switchy>input:active+.toggle {
    box-shadow: 0 0 5px 3px rgba(0, 119, 200, 0.50);
}

/* Rounded switch corners */
.toggle-switchy>input+.toggle {
    border-radius: 4px;
}

.toggle-switchy>input+.toggle .switch {
    border-radius: 6px;
}

/* //////////////////////////
CORE STYLES BELOW - NO TOUCHY
////////////////////////// */
.toggle-switchy {
    display: inline-flex;
    align-items: center;
    user-select: none;
    position: relative;
    vertical-align: middle;
    margin-bottom: 0;
}

.toggle-switchy:hover {
    cursor: pointer;
}

.toggle-switchy>input {
    position: absolute;
    opacity: 0;
}

.toggle-switchy>input+.toggle {
    align-items: center;
    position: relative;
}

.toggle-switchy>input+.toggle {
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
}

.toggle-switchy>input[disabled]+.toggle {
    opacity: 0.5;
}

.toggle-switchy>input[disabled]+.toggle:hover {
    cursor: not-allowed;
}

.toggle-switchy>input+.toggle {
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
}

.toggle-switchy>input+.toggle>.switch {
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    z-index: 3;
    box-sizing: border-box;
}

/* Labels */
.toggle-switchy>input+.toggle:before,
.toggle-switchy>input+.toggle:after {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 2;
    height: 100%;
}

.toggle-switchy>input+.toggle:before {
    right: 55%;
}

.toggle-switchy>input+.toggle:after {
    left: 50%;
}

.toggle-switchy>input+.toggle+.label {
    margin-left: 10px;
}

.toggle-switchy[data-label='left']>input+.toggle {
    order: 2;
}

.toggle-switchy[data-label='left']>input+.toggle+.label {
    order: 1;
    margin-left: 0;
    margin-right: 10px;
}

/* Show / Hide */
.toggle-switchy>input+.toggle:before {
    opacity: 0;
}

.toggle-switchy>input:checked+.toggle:before {
    opacity: 1;
}

.toggle-switchy>input:checked+.toggle:after {
    opacity: 0;
}

/* Transitions */
.toggle-switchy>input+.toggle {
    transition: background 200ms linear, box-shadow 200ms linear;
}

.toggle-switchy>input+.toggle:before,
.toggle-switchy>input+.toggle:after {
    transition: all 200ms linear;
}

.toggle-switchy>input+.toggle>.switch {
    transition: right 200ms linear, border-color 200ms linear;
}

/* //////////////////////////
CORE STYLES ABOVE - NO TOUCHY
////////////////////////// */

/* Size: Extra Large */
.toggle-switchy[data-size='xl']>input+.toggle {
    width: 85px;
    height: 40px;
}

.toggle-switchy[data-size='xl']>input+.toggle>.switch {
    width: 40px;
}

.toggle-switchy[data-size='xl']>input+.toggle:before,
.toggle-switchy[data-size='xl']>input+.toggle:after {
    font-size: 1.2rem;
}

.toggle-switchy[data-size='xl']>input:not(:checked)+.toggle>.switch {
    right: calc(100% - 40px);
}

/* Size: Large */
.toggle-switchy[data-size='lg']>input+.toggle {
    width: 75px;
    height: 35px;
}

.toggle-switchy[data-size='lg']>input+.toggle>.switch {
    width: 35px;
}

.toggle-switchy[data-size='lg']>input+.toggle:before,
.toggle-switchy[data-size='lg']>input+.toggle:after {
    font-size: 1rem;
}

.toggle-switchy[data-size='lg']>input:not(:checked)+.toggle>.switch {
    right: calc(100% - 35px);
}

/* Size: Default (Medium) */
.toggle-switchy>input+.toggle {
    width: 65px;
    height: 30px;
}

.toggle-switchy>input+.toggle>.switch {
    width: 30px;
}

.toggle-switchy>input+.toggle:before,
.toggle-switchy>input+.toggle:after {
    font-size: 0.8rem;
}

.toggle-switchy>input:not(:checked)+.toggle>.switch {
    right: calc(100% - 30px);
}

/* Size: Small */
.toggle-switchy[data-size='sm']>input+.toggle {
    width: 55px;
    height: 25px;
}

.toggle-switchy[data-size='sm']>input+.toggle>.switch {
    width: 25px;
}

.toggle-switchy[data-size='sm']>input+.toggle:before,
.toggle-switchy[data-size='sm']>input+.toggle:after {
    font-size: 0.7rem;
}

.toggle-switchy[data-size='sm']>input:not(:checked)+.toggle>.switch {
    right: calc(100% - 25px);
}

/* Size: Extra Small */
.toggle-switchy[data-size='xs']>input+.toggle {
    width: 45px;
    height: 20px;
}

.toggle-switchy[data-size='xs']>input+.toggle>.switch {
    width: 20px;
}

.toggle-switchy[data-size='xs']>input+.toggle:before,
.toggle-switchy[data-size='xs']>input+.toggle:after {
    font-size: 0.5rem;
}

.toggle-switchy[data-size='xs']>input:not(:checked)+.toggle>.switch {
    right: calc(100% - 20px);
}

/* Style: Rounded */
.toggle-switchy[data-style='rounded']>input+.toggle,
.toggle-switchy[data-style='rounded']>input+.toggle>.switch {
    border-radius: 50px;
}

.toggle-switchy[data-style='rounded']>input+.toggle:before {
    right: 50%;
}

.toggle-switchy[data-style='rounded']>input+.toggle:after {
    left: 50%;
}

/* Style: Square */
.toggle-switchy[data-style='square']>input+.toggle {
    border-radius: 0;
}

.toggle-switchy[data-style='square']>input+.toggle .switch {
    border-radius: 0;
}

/* Text: Off */
.toggle-switchy[data-text='false']>input+.toggle:before,
.toggle-switchy[data-text='false']>input+.toggle:after {
    content: '';
}

.toggle-switchy[data-text='false'][data-size='xl']>input+.toggle {
    width: 80px;
}

.toggle-switchy[data-text='false'][data-size='lg']>input+.toggle {
    width: 70px;
}

.toggle-switchy[data-text='false']>input+.toggle {
    width: 60px;
}

.toggle-switchy[data-text='false'][data-size='sm']>input+.toggle {
    width: 50px;
}

.toggle-switchy[data-text='false'][data-size='xs']>input+.toggle {
    width: 40px;
}

/* Color: Red */
.toggle-switchy[data-color='red']>input:checked+.toggle {
    background: #e74c3c;
}

.toggle-switchy[data-color='red']>input:checked+.toggle>.switch {
    border-color: #e74c3c;
}

/* Color: Orange */
.toggle-switchy[data-color='orange']>input:checked+.toggle {
    background: #e67e22;
}

.toggle-switchy[data-color='orange']>input:checked+.toggle>.switch {
    border-color: #e67e22;
}

/* Color: Yellow */
.toggle-switchy[data-color='yellow']>input:checked+.toggle {
    background: #f1c40f;
}

.toggle-switchy[data-color='yellow']>input:checked+.toggle>.switch {
    border-color: #f1c40f;
}

/* Color: Green */
.toggle-switchy[data-color='green']>input:checked+.toggle {
    background: #2ecc71;
}

.toggle-switchy[data-color='green']>input:checked+.toggle>.switch {
    border-color: #2ecc71;
}

/* Color: Blue */
.toggle-switchy[data-color='blue']>input:checked+.toggle {
    background: #3498db;
}

.toggle-switchy[data-color='blue']>input:checked+.toggle>.switch {
    border-color: #3498db;
}

/* Color: Purple */
.toggle-switchy[data-color='purple']>input:checked+.toggle {
    background: #9b59b6;
}

.toggle-switchy[data-color='purple']>input:checked+.toggle>.switch {
    border-color: #9b59b6;
}

// /* Color: Gray */
// .toggle-switchy[data-color='gray']>input:checked+.toggle {
//     background: #555;
// }

// .toggle-switchy[data-color='gray']>input:checked+.toggle>.switch {
//     border-color: #555;
// }

// [type="checkbox"] {
//     position: absolute;
//     left: -9999px;
// }

// .switches {
//     max-width: 500px;
//     width: 95%;
//     margin: 50px auto 0;
//     border-radius: 5px;
//     color: var(--white);
//     background: var(--blue);
// }

// .switches li {
//     position: relative;
//     counter-increment: switchCounter;
// }

// .switches li:not(:last-child) {
//     border-bottom: 1px solid var(--gray);
// }

// .switches li::before {
//     content: counter(switchCounter);
//     position: absolute;
//     top: 50%;
//     left: -30px;
//     transform: translateY(-50%);
//     font-size: 2rem;
//     font-weight: bold;
//     color: var(--pink);
// }

// .switches label {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 15px;
// }

// .switches span:last-child {
//     position: relative;
//     width: 50px;
//     height: 26px;
//     border-radius: 15px;
//     box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
//     background: var(--gray);
//     transition: all 0.3s;
// }

// .switches span:last-child::before,
// .switches span:last-child::after {
//     content: "";
//     position: absolute;
// }

// .switches span:last-child::before {
//     left: 1px;
//     top: 1px;
//     width: 24px;
//     height: 24px;
//     background: var(--white);
//     border-radius: 50%;
//     z-index: 1;
//     transition: transform 0.3s;
// }

.form-switch>.form-check-input,
.form-switch>.form-check-input:focus {
    background-color: #e74c3c;
    width: 4em !important;
    height: 30px;
    background-image: url('../assets/images/uncheck-switcher.svg') !important;
    background-size: 34px 14px;
    border: none;
    box-shadow: none;
}

// .switches [type="checkbox"]:checked+label span:last-child {
//     background: var(--green);
// }

// .switches [type="checkbox"]:checked+label span:last-child::before {
//     transform: translateX(24px);
// }

.form-switch>.form-check-input:checked {
    // width: 14px;
    // height: 14px;
    // /*right: auto;*/
    // left: 8px;
    border: none;
    background-color: #2ecc71;
    background-image: url('../assets/images/checkmark-switcher.svg') !important;
    background-size: 34px 14px;
    box-shadow: none;
}

.polish-switch>.form-check-input,
.polish-switch>.form-check-input:focus {
    width: 6em !important;
    background-size: 57px 48px;
    background-image: url('../assets/images/polish-switch-uncheck.svg') !important;
}

.polish-switch>.form-check-input:checked {
    background-size: 57px 48px;
    background-image: url('../assets/images/polish-switch.svg') !important;
}

@media screen and (max-width: 600px) {
    .switches li::before {
        display: none;
    }
}